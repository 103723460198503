import React, { useCallback, useEffect, useState } from "react";

import FormHelperText from "@material-ui/core/FormHelperText";
import TextField from "@material-ui/core/TextField";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { Trans, useTranslation } from "next-i18next";
import type { DropzoneInputProps, FileWithPath } from "react-dropzone";
import { useDropzone } from "react-dropzone";
import { useForm } from "react-hook-form";

import { useRouter } from "next/router";

import { Checkbox } from "@/components/checkbox";
import { COLUMN, COLCOUNT, Column, Hidden, Row } from "@/components/grid";
import { Transdown } from "@/components/i18n";
import { Spacer } from "@/components/layout/components";
import { Md } from "@/components/markdown";
import { Typography } from "@/components/typography/typography";
import {
	DROPZONE_CONFIG,
	FIXED_FIELDS_FILES,
	FIXED_FIELDS_TEXT,
	MAX_FILE_SIZE,
} from "@/constants/greenhouse";
import { StyledArrowRightIcon } from "@/design-system/atoms/button";
import { StyledCheckboxLabel } from "@/design-system/molecules/form/checkbox";
import { endpoints } from "@/endpoints";
import { useJob } from "@/hooks/jobs";
import { useNewsletterSignup } from "@/hooks/newsletter";
import { useTrackingEvent } from "@/hooks/tag-manager";
import { FONT_WEIGHT, TypographyVariant } from "@/theme";

import type { GreenhouseFormProps, GreenhouseApplicationFormProps } from "../";
import { GreenhouseFormKeys as FormKeys } from "../";
import {
	Dropped,
	Droppedzone,
	Dropzone,
	DropzoneLabel,
	Ellipsis,
	FlexButton,
	FormBox,
	RoundButton,
	StyledCircularProgress,
	StyledFileLabel,
} from "../";

export const JobApplicationForm = ({
	forwardedRef,
	jobLocations,
	textCollection,
	jobId,
	isAgentApplicationForm,
}: GreenhouseApplicationFormProps) => {
	const {
		register,
		handleSubmit: handleFormSubmit,
		errors,
		watch,
	} = useForm<GreenhouseFormProps>({
		defaultValues: {
			[FormKeys.firstName]: null,
			[FormKeys.lastName]: null,
			[FormKeys.location]: "",
			[FormKeys.email]: null,
			[FormKeys.resume]: undefined,
			[FormKeys.phone]: undefined,
			[FormKeys.consent]: false,
			[FormKeys.newsletterConsent]: false,
			...(!isAgentApplicationForm && { [FormKeys.coverLetter]: undefined }),
		},
	});
	const { query } = useRouter();

	const { t } = useTranslation(["common", "forms"]);

	const [selectedJobId, setSelectedJobId] = useState<string>();

	const { gtmEventType, jobQuestions } = useJob(selectedJobId);

	const trackEvent = useTrackingEvent();
	const [resumeFiles, setResumeFiles] = useState<FileWithPath[]>([]);
	const [coverLetterFiles, setCoverLetterFiles] = useState<FileWithPath[]>([]);

	const [greenhouseSuccess, setGreenhouseSuccess] = useState(false);
	const [greenhouseError, setGreenhouseError] = useState(false);
	const [greenhouseLoading, setGreenhouseLoading] = useState(false);

	const { subscribe } = useNewsletterSignup();

	const handleSubmit = useCallback(
		async formPayload => {
			try {
				setGreenhouseLoading(true);
				if (formPayload.newsletterConsent) {
					void subscribe(formPayload.email);
				}

				const formData = new FormData();

				formData.append(FormKeys.firstName, formPayload[FormKeys.firstName]);
				formData.append(FormKeys.lastName, formPayload[FormKeys.lastName]);
				formData.append(FormKeys.email, formPayload[FormKeys.email]);
				formData.append(FormKeys.phone, formPayload[FormKeys.phone]);
				formData.append(
					"data_compliance[gdpr_consent_given]",
					formPayload[FormKeys.consent]
				);

				// Append any dynamic questions
				jobQuestions.forEach(question => {
					const name = question.fields[0].name;
					formData.append(name, formPayload[name]);
				});

				// gh_src is appended by and to Greeenhouse Tracking Links
				// It is used for tracking inside Greenhouse for our recruitment team
				if (query["gh_src"]) {
					formData.append("mapped_url_token", query["gh_src"] as string);
				}

				if (resumeFiles.length > 0) {
					formData.append(FormKeys.resume, resumeFiles[0]);
				}

				if (coverLetterFiles.length > 0) {
					formData.append(FormKeys.coverLetter, coverLetterFiles[0]);
				}

				const result = await fetch(endpoints.api.greenhouse.baseUrl(), {
					method: "POST",
					headers: {
						"x-greenhouse-jobid": selectedJobId,
					},
					body: formData,
				});

				if (result.status !== 200) {
					throw new Error(`with status ${result.status}`);
				}

				setGreenhouseSuccess(true);

				trackEvent({
					event: "formSubmission",
					eventType: gtmEventType,
					// https://mece.atlassian.net/browse/EDP-3807
					formData: {
						email: formPayload.email ?? null,
						phone: formPayload.phone ?? null,
					},
				});
			} catch (error: unknown) {
				console.error("Error: Greenhouse job application", error);
				setGreenhouseError(true);
			} finally {
				setGreenhouseLoading(false);
			}
		},
		[
			resumeFiles,
			subscribe,
			trackEvent,
			jobQuestions,
			coverLetterFiles,
			selectedJobId,
			gtmEventType,
			query,
		]
	);

	const onDropCoverLetter = acceptedFiles => {
		setCoverLetterFiles(s => [...s, ...acceptedFiles]);
	};

	const onDropResume = acceptedFiles => {
		setResumeFiles(s => [...s, ...acceptedFiles]);
	};

	const removeCoverLetterFile = file => () => {
		setCoverLetterFiles(s => {
			const files = [...s];
			files.splice(files.indexOf(file), 1);
			return files;
		});
	};

	const removeResumeFile = file => () => {
		setResumeFiles(s => {
			const files = [...s];
			files.splice(files.indexOf(file), 1);
			return files;
		});
	};

	const {
		getRootProps: getRootPropsCoverLetter,
		getInputProps: getInputPropsCoverLetter,
		open: openCoverLetter,
		fileRejections: fileRejectionsCoverLetter,
	} = useDropzone({
		onDrop: onDropCoverLetter,
		...DROPZONE_CONFIG,
	});
	const { ref: inputRefCoverLetter, ...inputPropsCoverLetter } = getInputPropsCoverLetter({
		name: FormKeys.coverLetter,
	}) as DropzoneInputProps & { ref: React.MutableRefObject<HTMLInputElement> };

	const {
		getRootProps: getRootPropsResume,
		getInputProps: getInputPropsResume,
		open: openResume,
		fileRejections: fileRejectionsResume,
	} = useDropzone({
		onDrop: onDropResume,
		...DROPZONE_CONFIG,
	});
	const { ref: inputRefResume, ...inputPropsResume } = getInputPropsResume({
		name: FormKeys.resume,
	}) as DropzoneInputProps & { ref: React.MutableRefObject<HTMLInputElement> };

	const postCareerErrorMessage = textCollection.items.find(
		({ id }) => id === "postCareerError"
	).description;
	const postCareerSuccessMessage = textCollection.items.find(
		({ id }) => id === "postCareerSuccess"
	).description;

	const watchJobLocation = watch(FormKeys.location);

	useEffect(() => {
		if (jobId) {
			setSelectedJobId(jobId.toString());
			return;
		}

		// If there is only 1 attached jobLocation, use it directly
		if (jobLocations?.length === 1) {
			setSelectedJobId(jobLocations[0].jobId);
			return;
		}

		// Else get from passed in job locations from Contentful
		const id =
			jobLocations?.find(({ jobLocation }) => jobLocation === watchJobLocation)?.jobId ||
			jobLocations?.[0].jobId;
		setSelectedJobId(id);
	}, [jobId, jobLocations, watchJobLocation]);

	return (
		<FormBox ref={forwardedRef} data-test-id="job-application:form" id="contact-form">
			{greenhouseSuccess ? (
				<div data-test-id="job-application:success">
					<Md source={postCareerSuccessMessage} />
				</div>
			) : greenhouseError ? (
				<div data-test-id="job-application:error">
					<Md source={postCareerErrorMessage} />
				</div>
			) : (
				<>
					<Typography
						tight
						component="h3"
						id="apply-dialog-aria-label"
						variant={TypographyVariant.headlineSerifLG}
					>
						{t("forms:apply-now:title")}
					</Typography>
					<Typography bottom light tight weight={FONT_WEIGHT.light}>
						{t("forms:in-two-minutes")}
					</Typography>
					<Spacer spacing="xxs" />
					<form
						noValidate
						data-test-id="job-application:form"
						onSubmit={handleFormSubmit(handleSubmit)}
					>
						<Row>
							{jobLocations?.length > 1 && (
								<Column>
									<TextField
										fullWidth
										required
										select
										error={Boolean(errors[FormKeys.location])}
										helperText={!errors[FormKeys.location] && " "}
										id={FormKeys.location}
										label={t("forms:location.label")}
										name={FormKeys.location}
										SelectProps={{
											native: true,
										}}
										inputRef={register({ required: true })}
										inputProps={{
											"data-test-id": "job-application:location",
										}}
									>
										<option disabled hidden value="" />
										{jobLocations.map(({ sys: { id }, jobLocation }) => (
											<option key={id} value={jobLocation}>
												{jobLocation}
											</option>
										))}
									</TextField>
									{errors[FormKeys.location] && (
										<FormHelperText
											error
											data-test-id="job-application:error-location"
											role="alert"
										>
											{t("forms:location.error")}
										</FormHelperText>
									)}
								</Column>
							)}
							<>
								{FIXED_FIELDS_TEXT.map(fieldName => {
									return (
										<Column key={`col-${fieldName}`} l={COLUMN.TWO}>
											<TextField
												required
												fullWidth
												label={t(`forms:${fieldName}.label`)}
												id={fieldName}
												name={fieldName}
												error={Boolean(errors[fieldName])}
												helperText={!errors[fieldName] && " "}
												inputProps={{
													"data-test-id": `job-application:${fieldName}`,
												}}
												inputRef={register({ required: true })}
											/>
											{errors[fieldName] && (
												<FormHelperText
													error
													data-test-id={`job-application:error-${fieldName}`}
													role="alert"
												>
													{t(`forms:${fieldName}.error`)}
												</FormHelperText>
											)}
										</Column>
									);
								})}
							</>
							<>
								{jobQuestions.map(question => {
									const name = question.fields?.[0]?.name;
									const isRequired = question.required;
									const type = question.fields?.[0]?.type;
									const options = question.fields?.[0]?.values;

									// These case values are coming from Greenhouse
									switch (type) {
										case "multi_value_single_select":
											return (
												<Column>
													<TextField
														fullWidth
														required
														select
														data-test-id="valuation-property-intent:intent"
														error={Boolean(errors[name])}
														helperText={!errors[name] && " "}
														id={name}
														name={name}
														inputRef={register({
															required: isRequired,
														})}
														label={question.label}
														SelectProps={{ native: true }}
													>
														<option hidden value="" />
														{options.map((option, index) => {
															/**
															 * We try to use a translation that we may have in Tolgee
															 * for an option label coming from a Greenhouse question.
															 * If we don't, Trans falls back to option.label.
															 */
															const translationKey = `forms:greenhouse-option.${option.label}`;
															return (
																<option
																	key={`${name}.value${index}`}
																	value={option.value}
																>
																	<Trans
																		i18nKey={translationKey}
																		defaults={option.label}
																	/>
																</option>
															);
														})}
													</TextField>
												</Column>
											);

										case "textarea":
										case "input_text":
											return (
												<Column>
													<TextField
														fullWidth
														required={isRequired}
														label={question.label}
														id={name}
														name={name}
														error={Boolean(errors[name])}
														helperText={!errors[name] && " "}
														inputRef={register({
															required: isRequired,
														})}
													/>
													{errors[name] && (
														<FormHelperText error role="alert">
															{t("forms:required-field")}
														</FormHelperText>
													)}
												</Column>
											);
									}
								})}
							</>
							<>
								{FIXED_FIELDS_FILES.map(fieldName => {
									const isCoverLetter = fieldName === FormKeys.coverLetter;

									// No cover letter on agent application form
									if (isCoverLetter && isAgentApplicationForm) {
										return;
									}
									const isRequired = !isAgentApplicationForm && !isCoverLetter;

									const files = isCoverLetter ? coverLetterFiles : resumeFiles;
									const removeFile = isCoverLetter
										? removeCoverLetterFile
										: removeResumeFile;
									const getRootProps = isCoverLetter
										? getRootPropsCoverLetter
										: getRootPropsResume;
									const inputProps = isCoverLetter
										? inputPropsCoverLetter
										: inputPropsResume;
									const inputRef = isCoverLetter
										? inputRefCoverLetter
										: inputRefResume;
									const openFileSelect = isCoverLetter
										? openCoverLetter
										: openResume;
									const fileRejections = isCoverLetter
										? fileRejectionsCoverLetter
										: fileRejectionsResume;

									const label = isRequired
										? `${t(`forms:add-${fieldName}.label`)}*`
										: t(`forms:add-${fieldName}.label-optional`);

									return (
										<Column
											key={`col-${fieldName}`}
											s={isAgentApplicationForm && 0}
											l={`var(${COLCOUNT})`}
										>
											{isCoverLetter && <Spacer spacing="xxs" />}
											{files.length > 0 ? (
												<>
													<Typography
														bottom
														tight
														weight={FONT_WEIGHT.light}
													>
														{t(`forms:${fieldName}.label`)}
													</Typography>
													<Droppedzone>
														{files.map(file => (
															<Dropped key={file.path}>
																<DropzoneLabel>
																	<Ellipsis tight>
																		{file.path}
																	</Ellipsis>
																</DropzoneLabel>
																<RoundButton
																	type="button"
																	onClick={removeFile(file)}
																>
																	<DeleteIcon />
																</RoundButton>
															</Dropped>
														))}
													</Droppedzone>
												</>
											) : (
												<Dropzone {...getRootProps()}>
													<input
														{...inputProps}
														name={fieldName}
														ref={element => {
															register(element, {
																required: isRequired,
																validate: value => {
																	const file =
																		value?.length > 0 &&
																		value[0];
																	return (
																		!file ||
																		file?.size <= MAX_FILE_SIZE
																	);
																},
															});
															inputRef.current = element;
														}}
													/>
													<DropzoneLabel>
														<StyledFileLabel
															error={Boolean(errors[fieldName])}
															tight
														>
															{label}
														</StyledFileLabel>
													</DropzoneLabel>
													<RoundButton
														type="button"
														onClick={openFileSelect}
													>
														<AddIcon />
													</RoundButton>
												</Dropzone>
											)}
											{fileRejections.length > 0 && (
												<FormHelperText
													error
													data-test-id={`job-application:error-${fieldName}`}
													role="alert"
												>
													{t(
														`forms:file.${fileRejections[0].errors[0].code}`,
														{
															maxSize: MAX_FILE_SIZE / 1000000,
														}
													)}
												</FormHelperText>
											)}
											{errors[fieldName] && (
												<FormHelperText error role="alert">
													{t("forms:required-field")}
												</FormHelperText>
											)}
										</Column>
									);
								})}
							</>
							<Spacer spacing="s" />
						</Row>
						<StyledCheckboxLabel
							data-test-id="job-application:consent"
							label={<Transdown i18nKey="forms:consent.label" />}
							inputRef={register({ required: true })}
							error={Boolean(errors[FormKeys.consent])}
							control={<Checkbox id={FormKeys.consent} name={FormKeys.consent} />}
						/>
						{errors[FormKeys.consent] ? (
							<FormHelperText
								error
								data-test-id="job-application:error-consent"
								role="alert"
							>
								{t("forms:consent.error")}
							</FormHelperText>
						) : (
							<FormHelperText>&nbsp;</FormHelperText>
						)}
						<Hidden l>
							<StyledCheckboxLabel
								data-test-id="job-application:newsletter-consent"
								label={t("forms:newsletter.consent.label-simple")}
								inputRef={register}
								control={
									<Checkbox
										id={FormKeys.newsletterConsent}
										name={FormKeys.newsletterConsent}
									/>
								}
							/>
							<Spacer spacing="s" />
						</Hidden>
						<FlexButton
							type="submit"
							data-test-id="job-application:submit"
							disabled={greenhouseLoading}
						>
							{greenhouseLoading && (
								<StyledCircularProgress size={24} color="inherit" />
							)}
							{t("forms:apply-now:button-label")}
							<StyledArrowRightIcon />
						</FlexButton>
					</form>
				</>
			)}
		</FormBox>
	);
};
